import React, {useEffect, useState} from "react";
import {List, ListItem, ListItemButton, TextField} from "@mui/material";
import {NewPaperDialog} from "./NewPaperDialog";
import axios from "axios";
import {host} from "./config";

export const BibEntry = ({entry, showLink,id}) => {
    const sanitize = (str) => {
        return str.replace("\\n", " ").replace("{", "").replace("}", "");
    }
    const authors = sanitize(entry.author? entry.author : "")
    const title = sanitize(entry.title? entry.title : "");
    const journal = sanitize(entry.journal? entry.journal: (entry.booktitle? entry.booktitle : ""));
    const year = entry.year;

    return (
        <div>
            <p>
                {id && `[${id}]`} {authors}. {year}. <strong>{title}</strong>. {journal}.
            </p>
            {entry.url && showLink && <p><a href={entry.url} target="_blank">Open paper</a></p>}
        </div>
    );
}

export const BibSelection = ({bibList, onSelect}) => {
    const [filteredPaperList, setFilteredPaperList] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [newPaperDialogOpen, setNewPaperDialogOpen] = useState(false);

    const filterPaper = (paperList,filterText) => {
        if (filterText === "") {
            setFilteredPaperList(paperList)
            return
        }
        console.log(paperList)
        const filtered = paperList.filter((paper) => {
            return paper.entry.title.toLowerCase().includes(filterText) || paper.entry.author.toLowerCase().includes(filterText)
        })
        setFilteredPaperList(filtered)
    }
    useEffect(() => {
        filterPaper(bibList,filterText)

    }, [bibList,filterText]);

    return (<div>
            <NewPaperDialog open={newPaperDialogOpen} onClose={() => setNewPaperDialogOpen(false)} handleResult={onSelect}></NewPaperDialog>
            <TextField variant="standard" onChange={(event)=>setFilterText(event.target.value)}></TextField>
            <List sx={{overflowY:"auto", maxHeight:"400px"}}>
                {filteredPaperList.map((paper) => {
                    return (
                        <ListItem key={paper.id}>
                            <ListItemButton onClick={() => {
                                onSelect(paper.id)
                            }}>

                                <BibEntry entry={paper.entry}/>
                            </ListItemButton>
                        </ListItem>

                    )
                })}
                <ListItem key={0}>
                    <ListItemButton onClick={() => setNewPaperDialogOpen(true)}>
                        Add new paper
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    )
}

export const fetchBibList = async () => {
    try {
        const response = await axios.get(`${host}/api/list_bib`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return await response.data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
};