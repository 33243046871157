import {Button, ButtonGroup, Dialog, DialogTitle, List, ListItem, ListItemButton, Modal} from "@mui/material";
import {Excalidraw, exportToBlob} from "@excalidraw/excalidraw";
import React, {useEffect, useState} from "react";
import {host} from "./config";

export const ExcalidrawModal = ({open, handleClose, handleResult}) => {
    const [drawingList, setDrawingList] = useState([]);
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [showSelection, setShowSelection] = useState(false);
    const [drawingId, setDrawingId] = useState(0);
    const saveDrawing = async (base64Data, sceneData) => {
        const response = await fetch(`${host}/api/save_drawing`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                id: drawingId,
                sceneData: sceneData,
                image: base64Data,
            }),
        });
        return await response.json();
    };
    const fetchDrawingList = async () => {
        const response = await fetch(`${host}/api/list_drawings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        const data = await response.json();
        setDrawingList(data.data);
        setShowSelection(true)
    }
    const handleExportImage = async () => {
        const excalidrawInstance = excalidrawAPI;
        const sceneData = excalidrawInstance.getSceneElements();
        // Export Excalidraw content to blob
        const blob = await exportToBlob({elements: sceneData});
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Data = reader.result;
            saveDrawing(base64Data, sceneData);
            handleResult(base64Data)
            handleClose()
        };
        reader.readAsDataURL(blob);
    };
    const handleLoadDrawing = async (drawingId) => {
        const response = await fetch(`${host}/api/load_drawing?id=${drawingId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        const data = await response.json();
        const excalidrawInstance = excalidrawAPI;
        excalidrawInstance.updateScene({"elements": data.data});
        setShowSelection(false)
        setDrawingId(drawingId)
    }
    return (
        <Dialog open={open} onClose={handleClose} fullScreen={true}>
            <div>
                <DialogTitle>Add Excalidraw Sketch</DialogTitle>
                {<Dialog open={showSelection} onClose={()=>setShowSelection(false)}>
                    <DialogTitle>Select Existing Drawing</DialogTitle>
                    <List>
                        {
                            drawingList.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemButton onClick={() => handleLoadDrawing(item.id)}>
                                    <img src={item.preview} alt="drawing" onClick={() => handleLoadDrawing(item.id)}/>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                    <div>

                    <Button onClick={() => setShowSelection(false)}>Close</Button>
                    </div>
                </Dialog>
                }
                <div style={{height: '80vh'}}>
                    <Excalidraw excalidrawAPI={setExcalidrawAPI}></Excalidraw>
                </div>
                <ButtonGroup>
                    <Button variant="outlined" onClick={fetchDrawingList}>Edit Existing</Button>
                    <Button onClick={handleExportImage} variant="contained" color="success">
                    Add Sketch
                </Button>
                <Button variant="outlined" onClick={handleClose}>Abort</Button>
                </ButtonGroup>

            </div>
        </Dialog>
    )

}