import {Dialog, DialogTitle, List, ListItem, ListItemButton, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import {BibEntry, BibSelection, fetchBibList} from "./bibliography";
import {NewPaperDialog} from "./NewPaperDialog";

export const LinkPaperDialog = ({open, onClose, handleResult}) => {
    const [paperList, setPaperList] = useState([]);
    useEffect(() => {


        fetchBibList().then((data)=>{setPaperList(data)}).catch((error)=>{console.log(error)})
    }, []);

    return (
        <div>

            <Dialog open={open} onClose={onClose}>

                <DialogTitle>Link paper</DialogTitle>
                <BibSelection bibList={paperList} onSelect={(id)=>{handleResult(id);onClose()}}> </BibSelection>
            </Dialog>
        </div>
    )
}