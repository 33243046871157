import Editor from "./Editor";
import React, {useEffect, useState} from "react";
import {ExcalidrawModal} from "./ExcalidrawModal";
import {LinkDocumentDialog} from "./LinkDocumentDialog";
import {LinkPaperDialog} from "./LinkPaperDialog";
import axios from "axios";
import {host} from "./config";

export const DocumentEditor = ({onChange,value}) => {
    const editorRef = React.createRef();
    const [excalidrawModalOpen, setExcalidrawModalOpen] = useState(false);
    const [linkDocumentDialogOpen, setLinkDocumentDialogOpen] = useState(false);
    const [linkPaperDialogOpen, setLinkPaperDialogOpen] = useState(false);
    useEffect(() => {
        if (editorRef&&editorRef.current&&editorRef.current.getInstance) {
            editorRef.current.getInstance().setMarkdown(value);
        }
    }, [value]);
    const handleEditorChange = (event) => {
        const content = editorRef.current.getInstance().getMarkdown();
        onChange(content);
        console.log(content)
    }
    const handleExcalidrawResult = (imgData) => {
        console.log(imgData)
        const editor = editorRef.current?.getInstance();
        const isMarkdown = editor.isMarkdownMode();
        if (!isMarkdown)
            editor.changeMode('markdown');
        editor.insertText("<img src=\"" + imgData + "\"/>");
        if (!isMarkdown)
            editor.changeMode('wysiwyg');
    }
    const handleLinkDocumentResult = (documentId,title) => {
        const editor = editorRef.current?.getInstance();
        const isMarkdown = editor.isMarkdownMode();
        if (!isMarkdown)
            editor.changeMode('markdown');
        editor.insertText("["+title+"]("+documentId+".md)");
        if (!isMarkdown)
            editor.changeMode('wysiwyg');

    }
    const handleLinkPaperResult = (paperId) => {
        const editor = editorRef.current?.getInstance();
        const isMarkdown = editor.isMarkdownMode();
        if (!isMarkdown)
            editor.changeMode('markdown');
        editor.insertText(`[\\[${paperId}\\]](../bibliography/${paperId}.md)`);
        if (!isMarkdown)
            editor.changeMode('wysiwyg');

    }
    function uploadImage(file) {
        // Create a FormData object to store the file data
        const formData = new FormData();
        formData.append('file', file);

        // Make a POST request using Axios to upload the file
        return axios.post(`${host}/api/upload_image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data for file upload
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        });
    }
    useEffect(() => {
        const editor = editorRef.current?.getInstance();
        if (editor) {
            console.log(editor)
            editor.addCommand('markdown', 'excalidraw', function () {
                setExcalidrawModalOpen(true);
            });
            editor.addCommand('wysiwyg', 'excalidraw', function () {
                setExcalidrawModalOpen(true);
            });
            editor.addCommand('markdown', 'linkDocument', function () {
                setLinkDocumentDialogOpen(true);
            });
            editor.addCommand('wysiwyg', 'linkDocument', function () {
                setLinkDocumentDialogOpen(true);
            });
            editor.addCommand('markdown', 'linkPaper', function () {
                setLinkPaperDialogOpen(true);
            });
            editor.addCommand('wysiwyg', 'linkPaper', function () {
                setLinkPaperDialogOpen(true);
            });
            editor.addHook("addImageBlobHook", (blob, callback) => {
                uploadImage(blob)
                    .then(response => {
                        const filename=response.data.filename
                        callback(`${host}/image/${filename}`,blob.name)
                    })
                    .catch(error => {
                        console.error('Error uploading file:', error);
                    });


            });
        }

    }, []);
    return (
        <div>
            <ExcalidrawModal open={excalidrawModalOpen}
                             handleClose={() => setExcalidrawModalOpen(false)}
                             handleResult={handleExcalidrawResult}></ExcalidrawModal>
            <LinkDocumentDialog open={linkDocumentDialogOpen} handleClose={setLinkDocumentDialogOpen} handleResult={handleLinkDocumentResult}></LinkDocumentDialog>
            {linkPaperDialogOpen&&<LinkPaperDialog open={linkPaperDialogOpen} onClose={()=>setLinkPaperDialogOpen(false)} handleResult={handleLinkPaperResult}></LinkPaperDialog>}
            <Editor
                ref={editorRef}
                previewStyle="vertical"
                initialEditType="wysiwyg"
                height='calc(100vh - 64px)'
                useCommandShortcut={true}
                onChange={handleEditorChange}
                toolbarItems={[
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'image', 'link'],
                    ['code', 'codeblock'],
                    [
                        {
                            "name": "excalidraw",
                            "tooltip": "excalidraw",
                            "command": "excalidraw",
                            "text": "E"
                        },
                        {
                            "name": "linkDocument",
                            "tooltip": "Link Document",
                            "command": "linkDocument",
                            "text": "L"
                        },
                        {
                            "name": "linkPaper",
                            "tooltip": "Link Paper",
                            "command": "linkPaper",
                            "text": "P"
                        }
                    ]]}
            />
        </div>)
}