import {Button, Dialog, DialogTitle, TextareaAutosize} from "@mui/material";
import {useState} from "react";
import {host} from "./config";

export const NewPaperDialog = ({open, onClose, handleResult}) => {
    const [bibtex, setBibtex] = useState("");
    const newPaper = async () => {
        const response = await fetch(`${host}/api/new_bib`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(
                bibtex
            ),
        });
        const data= await response.json()

        handleResult(data.id);
        onClose()
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Add new Paper
            </DialogTitle>
            <TextareaAutosize placeholder="Paste Bibtex" onChange={(event)=>setBibtex(event.target.value)}></TextareaAutosize>
            <Button onClick={newPaper}>Add Paper</Button>
        </Dialog>
    )
}