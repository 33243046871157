import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Navigate, Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    CircularProgress,
    IconButton,
    Box,
    Grid,
    Card,
    CardContent, CardHeader, ListItemButton, ListItem, List, TextField
} from '@mui/material';
import axios from 'axios';
import {DocumentPage} from "./DocumentPage";
import {Note, Notes} from "@mui/icons-material";
import {BibSelection, fetchBibList} from "./bibliography";
import {BibliographyPage} from "./BibliographyPage";
import {host} from "./config";

const AuthContext = React.createContext();
const NavBar = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton component={Link} to="/">
                    <Notes></Notes>
                </IconButton>
                <Box sx={{flexGrow: 1}}></Box>

                <Button color="inherit" component={Link} to="/logout">
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    );
};

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${host}/api/token`, {
                username: username,
                password: password,
            });
            localStorage.setItem('token', response.data.access_token);
            navigate("/")
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div>
            <NavBar></NavBar>
            <h2>Login Page</h2>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};
const LogoutPage = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        navigate("/login")
    };
    localStorage.removeItem('token');
    return (<Navigate to="/login"></Navigate>)
};
const SyncCard = () => {
    const [unCommitedChanges, setUnCommitedChanges] = useState(0);
    const [needsPush, setNeedsPush] = useState(false);
    const [commitMessage, setCommitMessage] = useState("");
    const fetchData = async () => {
        try {
            const response = await axios.get(`${host}/api/git_status`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data= await response.data;
            setUnCommitedChanges(data.uncommited_files)
            setNeedsPush(data.push_necessary)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const doSync = async () => {
        try {
            const response = await axios.post(`${host}/api/git_commit_and_push`,
                commitMessage
            , {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                }
            );
            const data= await response.data;
            return data;
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    const sync= ()=>{
        doSync().then((data)=> {
            fetchData()
        });
    }
    return (
        <Card sx={{height: "500px"}}>
            <CardContent>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                Sync via Git
            </Typography>

                <p>Uncomitted Files: <b style={{color:unCommitedChanges===0?"green":"red"}}>{unCommitedChanges}</b></p>
                <p>Needs Push: <b style={{color:(needsPush===false?"green":"red")}}>{needsPush?"Yes":"No"}</b></p>
                <p><TextField value={commitMessage} onChange={(event)=>setCommitMessage(event.target.value)}/> </p>
                <Button disabled={commitMessage.length===0} onClick={sync}>Sync</Button>
            </CardContent>
        </Card>
    )
}
const DocomentList = () => {
    const navigate = useNavigate();
    const [documentList, setDocumentList] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filteredDocumentList, setFilteredDocumentList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${host}/api/list`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setDocumentList(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        if (filterText === "") {
            setFilteredDocumentList(documentList)
            return
        }
        const filtered = documentList.filter((document) => {
            return document.title.toLowerCase().includes(filterText.toLowerCase())
        })
        setFilteredDocumentList(filtered)

    }, [documentList,filterText]);
    const newDocument = async () => {
        const response = await fetch(`${host}/api/new_document`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        });
        const data = await response.json()
        navigate(`/document/${data.id}.md`)
    }
    return (
        <Card sx={{height: "500px"}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    Documents
                </Typography>
                <TextField variant="standard" onChange={(event) => setFilterText(event.target.value)}></TextField>

                <List  sx={{overflowY:"auto", maxHeight:"400px"}}>
                    {filteredDocumentList.map((item, index) => {
                        return (
                            <ListItem key={index}>
                                <ListItemButton component={Link} to={`/document/${item.id}.md`}>
                                    <Notes/>&nbsp;{item.title}
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                    <ListItem>
                        <ListItemButton onClick={newDocument}>
                            Add new Document
                        </ListItemButton>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}
const BibliographyList = (props) => {
    const [bibliographyList, setBibliographyList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBibList().then((data) => {
            console.log(data)
            setBibliographyList(data);
        }).catch((error) => {
            console.error('Failed to fetch data:', error);
        });
    },[])
    return (
        <Card sx={{height: "500px"}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    Bibliography
                </Typography>
                <BibSelection bibList={bibliographyList} onSelect={(id) => navigate(`/bibliography/${id}.md`)}/>
            </CardContent>
        </Card>
    )
}
const HomePage = () => {


    return (
        <div>
            <NavBar/>
            <Grid container spacing={2}>
                <Grid xs={4} item>
                    <DocomentList/>
                </Grid>

                <Grid xs={4} item>
                    <BibliographyList/>

                </Grid>
                <Grid xs={4} item>
                    <SyncCard/>

                </Grid>
            </Grid>

        </div>
    );
};

const PrivateRoute = ({children}) => {
    return localStorage.getItem('token') ? (
        children
    ) : (
        <Navigate to="/login" replace={true}/>
    );
};


const App = () => {
    return (
        <AuthContext.Provider value={{}}>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Outlet/>
                            </PrivateRoute>
                        }
                    >
                        <Route index element={<HomePage/>}/>
                        <Route path="/document/:documentId.md" element={<DocumentPage/>}/>
                        <Route path="/bibliography/:paperId.md" element={<BibliographyPage/>}/>
                    </Route>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/logout" element={<LogoutPage/>}/>

                </Routes>
            </Router>
        </AuthContext.Provider>
    );
};

export default App;
