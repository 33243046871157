import {Link, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import '@toast-ui/editor/dist/toastui-editor.css';

import axios from "axios";
import Editor from "./Editor";
import {Excalidraw, exportToBlob, exportToCanvas, exportToSvg} from "@excalidraw/excalidraw";
import {ExcalidrawModal} from "./ExcalidrawModal";
import {Print, Notes, Book} from '@mui/icons-material';
import {
    AppBar, Box,
    Button,
    CircularProgress, Container,
    FormControlLabel, IconButton,
    Switch,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {DocumentEditor} from "./DocumentEditor";
import {DocumentViewer} from "./DocumentViewer";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {host} from "./config";
export const DocumentPage = (props) => {
    const {documentId} = useParams();
    const [storedDocumentData, setStoredDocumentData] = useState([]);
    const [storedDocumentContent, setStoredDocumentContent] = useState("");
    const [documentData, setDocumentData] = useState([]);
    const [documentContent, setDocumentContent] = useState(undefined);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [exportingBib, setExportingBib] = useState(false);
    const [initialDocumentContent, setInitialDocumentContent] = useState("");
    const viewerRef = useRef();
    useEffect(() => {
        if (JSON.stringify(documentData) !== JSON.stringify(storedDocumentData) || documentContent !== storedDocumentContent) {
            setUnsavedChanges(true);
        } else {
            setUnsavedChanges(false);
        }
    }, [documentData, storedDocumentData, documentContent, storedDocumentContent]);

    useEffect(() => {
        // This function will be called when the component mounts
        const intervalId = setInterval(() => {
            if (JSON.stringify(documentData) !== JSON.stringify(storedDocumentData) || documentContent !== storedDocumentContent) {
                axios.post(`${host}/api/save_document`, {
                    data: documentData,
                    content: documentContent,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }).then((response) => {
                    setStoredDocumentContent(response.data.content);
                    setStoredDocumentData(response.data.data);
                }).catch((error) => {
                    console.log(error);
                });
            }
        }, 1000); // 5000 milliseconds = 5 seconds

        // This cleanup function will be called when the component unmounts
        return () => clearInterval(intervalId);
    }, [documentData, storedDocumentData, documentContent, storedDocumentContent, documentId]); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${host}/api/load_document?id=${documentId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setDocumentData(response.data.data);
                setDocumentContent(response.data.content);
                setStoredDocumentData(response.data.data);
                setStoredDocumentContent(response.data.content);
                setInitialDocumentContent(response.data.content);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);
    const switchMode = (edit) => {
        if(!edit){
            setInitialDocumentContent(documentContent)
        }
        setEditEnabled(edit)
    }
    const setDocumentTitle = (event) => {
        setDocumentData({
            ...documentData,
            title: event.target.value,
        });
    }
    useEffect(() => {
        if(printing){
            handlePrint();
            setPrinting(false);
        }
    }, [printing]);

    const handlePrint = useReactToPrint({
        content: () => viewerRef.current,
        pageStyle: `@page { size: A4;}
.print-body .toastui-editor-contents ul,
.print-body .toastui-editor-contents ol {
  display: block;
  margin: 6px 0 10px;
  color: #222;
}
.print-body .toastui-editor-contents ul {
    list-style-type: disc;
    }
    


        `,
        copyStyles: true,
        bodyClass: 'print-body',
        documentTitle: documentData.title,

    });



    return (
    <div>
            <AppBar position="static">
                <Toolbar>

                    <IconButton component={Link} to="/" sx={{ marginRight: 20}}>
                        <Notes></Notes>
                    </IconButton>
                    <TextField variant="standard" value={documentData.title} onChange={setDocumentTitle}
                               style={{ backgroundColor: 'white' }} size="small" sx={{marginRight:5}}></TextField>
                    <FormControlLabel control={<Switch value={editEnabled} color="default" onChange={(_,checked)=>switchMode(checked)}/>} label={unsavedChanges?"Edit*":"Edit"} />

                    <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1}}>
                    </Typography>
                    {!editEnabled &&
                    <Button color="inherit" onClick={()=> {
                        setPrinting(true);
                    }
                    }>
                        <Print></Print>
                    </Button>
                    }
                    {!editEnabled &&
                        <Button color="inherit" onClick={()=> {
                            setExportingBib(true);
                        }
                        }>
                            <Book></Book>
                        </Button>
                    }

                    <Button color="inherit" component={Link} to="/logout">
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
        <Container maxWidth="lg" sx={{boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}} disableGutters={true}>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
            >
                { editEnabled &&
                    <DocumentEditor onChange={setDocumentContent} value={initialDocumentContent}/>
                }
                {(!editEnabled && documentContent!==undefined)&&

                    <DocumentViewer value={documentContent} ref={viewerRef} printing={printing} exportingBib={exportingBib} stopExportingBib={()=>setExportingBib(false)}/>
                }            </Box>
        </Container>


        </div>
    );
};