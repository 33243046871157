import {Button, Dialog, DialogTitle, List, ListItem, ListItemButton, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {host} from "./config";

export const LinkDocumentDialog=({open,handleClose,handleResult})=>{
    const [documentList, setDocumentList] = useState([]);
    const [selectedDocumentId, setSelectedDocumentId] = useState(0);
    const [linkText, setLinkText] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${host}/api/list`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setDocumentList(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();

    }, []);

    return (
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
                Link Document
          </DialogTitle>
          <List>
          { documentList.map((item, index) => {
              return (
              <ListItem key={index}>
                  <ListItemButton onClick={() => {setSelectedDocumentId(item.id);setLinkText(item.title)}}>
                      {"a"}
                      {item.title}
                  </ListItemButton>
              </ListItem>
              )
          })}
          </List>
          <TextField value={linkText} onChange={(event)=>{setLinkText(event.target.value)}}></TextField>
          <Button color="success" onClick={()=>{handleResult(selectedDocumentId,linkText); handleClose()}}>Add Link</Button>
      </Dialog>
    );
}