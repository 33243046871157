import {Link, useParams} from "react-router-dom";
import {
    AppBar, Box,
    Button,
    Container,
    FormControlLabel,
    IconButton,
    Switch, TextareaAutosize,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Notes, Print, Sync, Update} from "@mui/icons-material";
import {DocumentEditor} from "./DocumentEditor";
import {DocumentViewer} from "./DocumentViewer";
import React, {useEffect} from "react";
import axios from "axios";
import {BibEntry} from "./bibliography";
import {host} from "./config";

export const BibliographyPage = () => {
    const {paperId} = useParams();
    const [bibEntry, setBibEntry] = React.useState(null);
    const [bibtex, setBibtex] = React.useState("");
    const [storedBibtex, setStoredBibtex] = React.useState("");
    const [notes, setNotes] = React.useState("")
    const [storedNotes, setStoredNotes] = React.useState("")
    const [initialNotes, setInitialNotes] = React.useState("")
    const [unsavedChanges, setUnsavedChanges] = React.useState(false);
    useEffect(() => {
        if (bibtex !== storedBibtex || notes !== storedNotes) {
            setUnsavedChanges(true);
        } else {
            setUnsavedChanges(false);
        }
    }, [bibtex, storedBibtex, notes, storedNotes]);
    useEffect(() => {
        // This function will be called when the component mounts
        const intervalId = setInterval(() => {
            if (unsavedChanges) {
                axios.post(`${host}/api/save_bib`, {
                    id: paperId,
                    bibtex: bibtex,
                    notes: notes
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }).then((response) => {
                    setBibEntry(response.data.entry);
                    setStoredBibtex(response.data.bibtex);
                    setStoredNotes(response.data.notes);
                }).catch((error) => {
                    console.log(error);
                });
            }
        }, 1000); // 5000 milliseconds = 5 seconds

        // This cleanup function will be called when the component unmounts
        return () => clearInterval(intervalId);
    }, [unsavedChanges,notes,bibtex]); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        async function getBibEntry() {
            const response = await axios.get(`${host}/api/get_bib?id=${paperId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.data
            console.log(data)
            setBibEntry(data.entry);
            setBibtex(data.bibtex)
            setNotes(data.notes)
            setInitialNotes(data.notes)
            setStoredNotes(data.notes)
            setStoredBibtex(data.bibtex)
        }

        if (paperId)
            getBibEntry();
    }, [paperId]);
    return (
        <div>
            <AppBar position="static">
                <Toolbar>

                    <IconButton component={Link} to="/">
                        <Notes></Notes>
                    </IconButton>
                    {unsavedChanges&&<Sync/>}
                    <Box sx={{flexGrow: 1}}></Box>

                    <Button color="inherit" component={Link} to="/logout">
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg"
                       sx={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}
                       disableGutters={true}>
                <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="100vh"
                >
                    {bibEntry&&<BibEntry entry={bibEntry} showLink={true}/>}
                    <Typography variant="h6">
                        BibTex
                    </Typography>
                    <TextareaAutosize value={bibtex} style={{width:"100%"}} onChange={(event)=>setBibtex(event.target.value)}></TextareaAutosize>
                    <Typography variant="h6">
                        Notes
                    </Typography>
                    <DocumentEditor value={initialNotes} onChange={setNotes}></DocumentEditor>

                </Box>
            </Container>


        </div>)

}