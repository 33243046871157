import React from 'react';
import Editor from '@toast-ui/editor';
import chartPlugin from '@toast-ui/editor-plugin-chart';
import '@toast-ui/editor/dist/toastui-editor.css';
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.rootEl = React.createRef();
    }

    getRootElement() {
        return this.rootEl.current;
    }

    getInstance() {
        return this.editorInst;
    }

    getBindingEventNames() {
        return Object.keys(this.props)
            .filter((key) => /^on[A-Z][a-zA-Z]+/.test(key))
            .filter((key) => this.props[key]);
    }

    bindEventHandlers(props) {
        this.getBindingEventNames().forEach((key) => {
            const eventName = key[2].toLowerCase() + key.slice(3);

            this.editorInst.off(eventName);
            this.editorInst.on(eventName, props[key]);
        });
    }

    getInitEvents() {
        return this.getBindingEventNames().reduce((acc, key) => {
            const eventName = (key[2].toLowerCase() + key.slice(3));
            acc[eventName] = this.props[key];
            return acc;
        }, {});
    }

    componentDidMount() {
        this.editorInst = new Editor({
            el: this.rootEl.current,
            ...this.props,
            plugins:[[chartPlugin,{
                minWidth: 100,
                maxWidth: 600,
                minHeight: 100,
                maxHeight: 300
            }]],
            events: this.getInitEvents(),
        });
    }

    shouldComponentUpdate(nextProps) {
        const instance = this.getInstance();
        const { height, previewStyle } = nextProps;

        if (height && this.props.height !== height) {
            instance.setHeight(height);
        }

        if (previewStyle && this.props.previewStyle !== previewStyle) {
            instance.changePreviewStyle(previewStyle);
        }

        this.bindEventHandlers(nextProps);

        return false;
    }

    render() {
        return <div ref={this.rootEl} />;
    }
}
