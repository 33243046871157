import ViewerComponent from "./Viewer";
import React, {useEffect} from "react";
import {Box, Button, Dialog, DialogTitle, IconButton, TextareaAutosize} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {BibEntry, fetchBibList} from "./bibliography";
import {host} from "./config";


const BibDialog = ({open, handleClose, bibId}) => {
    const navigate = useNavigate();
    const [bibEntry, setBibEntry] = React.useState(null);
    useEffect(() => {
        async function getBibEntry() {
            const response = await axios.get(`${host}/api/get_bib?id=${bibId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.data
            console.log(data)
            setBibEntry(data.entry);
        }

        if (bibId)
            getBibEntry();
    }, [bibId]);
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>[{bibId}] <IconButton sx={{position: "absolute", top: "0", right: "0"}} onClick={handleClose}>
                <CloseIcon/>
            </IconButton></DialogTitle>
            {bibEntry && <BibEntry entry={bibEntry} showLink={true}/>}
            <Button onClick={() => navigate(`/bibliography/${bibId}.md`)}>Go to entry</Button>
        </Dialog>
    )
}
export const DocumentViewer = React.forwardRef((props, ref) => {
    const {value, printing, exportingBib, stopExportingBib} = props;
    const [bibliography, setBibliography] = React.useState([]);
    const [filteredBibliography, setFilteredBibliography] = React.useState([]);
    const [bibDialogOpen, setBibDialogOpen] = React.useState(false);
    const [bibDialogId, setBibDialogId] = React.useState(null);
    useEffect(() => {
        fetchBibList().then((data) => {
            console.log(data)
            setBibliography(data);
        }).catch((error) => {
            console.error('Failed to fetch data:', error);
        });
    }, [])
    useEffect(() => {
        // Function to handle click events on elements with a specific class
        const handleOnClick = (event) => {
            // Access the element that was clicked
            const clickedElement = event.target;
            event.preventDefault();
            setBibDialogId(clickedElement.dataset.bibid)
            setBibDialogOpen(true)
            // Your custom logic here
        };

        // Access the container element by ref
        const containerElement = ref.current;

        // Use querySelectorAll to select elements with a specific class
        const elementsWithClass = containerElement.querySelectorAll('.your-target-class');

        // Add onClick handler to each selected element
        elementsWithClass.forEach((element) => {
            element.addEventListener('click', handleOnClick);
        });

        // Cleanup event listeners when the component unmounts
        return () => {
            elementsWithClass.forEach((element) => {
                element.removeEventListener('click', handleOnClick);
            });
        };
    }, []); // The empty dependency array ensures that the effect runs only once on mount

    useEffect(() => {
        function extractIds(text) {
            // Regular expression to find all occurrences of [[id]]
            var regex = /\[\\\[(\d+)\\]]/g;

            var ids = [];

            var match;
            while ((match = regex.exec(text)) !== null) {
                ids.push(match[1]);
            }

            return ids;
        }

        const bibItems = extractIds(value);
        console.log("bibItems")
        console.log(bibItems)
        console.log(value)
        setFilteredBibliography(bibliography.filter((item) => {
            return bibItems.includes(`${item.id}`)
        }).sort((a, b) => {
            return bibItems.indexOf(`${a.id}`) - bibItems.indexOf(`${b.id}`)
        }))
    }, [value, bibliography]);
    return (
        <div>
            <BibDialog open={bibDialogOpen} handleClose={() => setBibDialogOpen(false)} bibId={bibDialogId}/>
            <Dialog open={exportingBib} onClose={stopExportingBib}>
                <DialogTitle> Export Bibliography <IconButton sx={{position: "absolute", top: "0", right: "0"}}
                                                   onClick={stopExportingBib}>
                    <CloseIcon/>
                </IconButton></DialogTitle>
                <TextareaAutosize value={filteredBibliography.map((item) => {
                    return item.bibtex
                }).join('\n')}> </TextareaAutosize>
            </Dialog>
            <div ref={ref} style={{backgroundColor: "white", padding: "10px"}}>
                <ViewerComponent initialValue={value} customHTMLRenderer={{
                    "link": (node, context) => {
                        const {origin, entering} = context;
                        const result = origin();
                        if (entering) {
                            if (node.destination.startsWith("../bibliography")) {
                                console.log(node)
                                console.log(result)
                                //result.attributes.target = '_blank';
                                result.attributes.href = '#';
                                result.attributes.class = 'your-target-class';
                                result.attributes['data-bibid'] = node.destination.replace("../bibliography/", "").replace(".md", "")
                            }
                        }
                        return result;
                    }
                }}/>
                {printing &&
                    <div className={"toastui-editor-contents"}>
                        <h3>Bibliography</h3>
                        {filteredBibliography.map((item) => {
                                console.log("showing bib item")
                                console.log(item)
                                return (
                                    <p><BibEntry id={item.id} entry={item.entry} showLink={false}></BibEntry></p>)
                            }
                        )}

                    </div>
                }
            </div>
        </div>
    )
})